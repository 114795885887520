import React from 'react';
import clsx from 'clsx';

export const SchedulerLinkContent = ({ label, icon, className, ...props }) => {
  return (
    <span
      className={clsx(
        'flex items-center cursor-pointer py-2.5 px-6',
        className
      )}
      {...props}
    >
      {icon && <span className="mr-3.5">{icon}</span>}
      <span className="text-sm font-semibold">{label}</span>
    </span>
  );
};
