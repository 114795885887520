import React from 'react';

export const ModalIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83404 16.6666V7.47914C5.83404 7.0208 6.0007 6.63192 6.33404 6.31247C6.66737 5.99303 7.0632 5.8333 7.52154 5.8333H16.6674C17.1257 5.8333 17.5181 5.9965 17.8445 6.32289C18.1708 6.64928 18.334 7.04164 18.334 7.49997V14.1666L14.1674 18.3333H7.5007C7.04237 18.3333 6.65001 18.1701 6.32362 17.8437C5.99723 17.5173 5.83404 17.125 5.83404 16.6666ZM1.6882 5.2083C1.60487 4.74997 1.69515 4.33678 1.95904 3.96872C2.22293 3.60067 2.58404 3.37497 3.04237 3.29164L12.084 1.68747C12.5424 1.60414 12.9556 1.69442 13.3236 1.9583C13.6917 2.22219 13.9174 2.5833 14.0007 3.04164L14.209 4.16664H12.5007L12.3549 3.3333L3.33404 4.93747L4.16737 9.6458V15.4583C3.94515 15.3333 3.75418 15.1666 3.59445 14.9583C3.43473 14.75 3.33404 14.5139 3.29237 14.25L1.6882 5.2083ZM7.5007 7.49997V16.6666H13.334V13.3333H16.6674V7.49997H7.5007Z"
        fill="#585858"
      />
    </svg>
  );
};
