import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateClient } from '../../hooks';
import { editClientFormSchema } from '../../constants';
import { showError, showSuccess } from '../../utils';
import InputMask from 'react-input-mask';

function convertToInternationalFormat(phoneNumber) {
  // Remove all non-numeric characters
  var cleanedNumber = phoneNumber.replace(/\D/g, '');

  // If the number starts with '1', remove it
  if (cleanedNumber.charAt(0) === '1') {
    cleanedNumber = cleanedNumber.substr(1);
  }

  // Add the country code
  var internationalNumber = '+1' + cleanedNumber;

  return internationalNumber;
}

export const EditClientForm = ({
  editableData,
  setIsLoading,
  closeAddNewClientModal,
  refetch,
  setPage,
}) => {
  const handleUpdateClient = useUpdateClient();
  const [formChanged, setFormChanged] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(editClientFormSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    const payload = {
      accountUid: editableData?.uid,
      input: {
        formalName: data?.name,
        phone: convertToInternationalFormat(data?.mobile),
      },
    };

    const response = await handleUpdateClient(payload);
    if (response?.data) {
      setPage(1);
      refetch();
      showSuccess('Client updated successfully');
      closeAddNewClientModal();
      reset();
      setIsLoading(false);
    } else {
      // Displaying the error message
      setIsLoading(false);
      showError(response);
    }
  };

  useEffect(() => {
    if (editableData) {
      setValue('email', editableData?.email);
      setValue('name', editableData?.formalName);
      setValue('mobile', editableData?.phone);
    }
  }, [editableData]);

  useEffect(() => {
    const name = watch('name');
    const mobile = watch('mobile');
    if (name === editableData?.formalName && mobile === editableData?.phone) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }
  }, [watch('name'), watch('mobile')]);

  return (
    <>
      <h2 className="font-semibold md:text-2xl text-base text-gray-dark">
        Edit Client
      </h2>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Email <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="email"
            control={control}
            disabled
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                placeholder="Email"
              />
            )}
          />
          {errors && errors?.email && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.email?.message}
            </p>
          )}
        </div>
        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Client Name <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="name"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-10 text-xs font-medium rounded-lg border border-gray-light console-input p-2"
                placeholder="Name"
              />
            )}
          />
          {errors && errors?.name && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.name?.message}
            </p>
          )}
        </div>

        <div className="my-3">
          <label className="text-sm  text-gray-dark">
            Mobile Number <span className="text-red-drk">*</span>
          </label>
          <Controller
            name="mobile"
            control={control}
            defaultValue="" // provide a default value if necessary
            render={({ field }) => (
              <InputMask
                {...field}
                placeholder="Enter your phone number"
                mask="+1 (999) 999 9999"
                alwaysShowMask
                maskChar="_"
                inputMode="numeric"
                className={`w-full text-xs font-medium rounded border border-gray-mercury console-input p-2 my-2`}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors && errors?.mobile && (
            <p className="text-red-drk md:text-r0.6875 text-r0.625 ">
              {errors?.mobile?.message}
            </p>
          )}
        </div>
        <button
          className={
            !formChanged
              ? 'text-gray-dark w-full p-2 mt-1 rounded-lg bg-gray-mercury'
              : ' w-full p-2 mt-1 rounded-lg bg-primary text-white-main hover:bg-blue-hover'
          }
          disabled={!formChanged}
        >
          Update
        </button>
      </form>
    </>
  );
};
